/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { FaLinkedin, FaInstagram } from 'react-icons/fa6';
import { IconButton } from '@mui/material';
import '../styles/team.css';
import advCard1 from '../images/adv-card1.jpg';
import advCard2 from '../images/adv-card2.jpg';
import advCard3 from '../images/adv-card3.jpg';
import advCard4 from '../images/adv-card4.jpg';

const teamData = [
  {
    image: advCard1,
    title: 'MED BRAZÃO\nOAB/RN 17.423',
    url1: 'https://www.linkedin.com/in/medbrazao',
    url2: 'https://www.instagram.com/medbrazao',
  },
  {
    image: advCard2,
    title: 'RAONI CASTRO\nOAB/AL 15.609',
    url1: 'https://www.linkedin.com/in/raoni-castro-3741469',
    url2: 'https://www.instagram.com/raonicastro.advogado',
  },
  {
    image: advCard3,
    title: 'PATRICIA MIRANDA\nOAB/RN 19.509',
    url1: 'https://br.linkedin.com/in/patricia-paula-de-miranda-189883a4',
    url2: 'https://www.instagram.com/patricia_paula',
  },
  {
    image: advCard4,
    title: 'ÍTALO CASTRO\nOAB/RN 17.392',
    url1: 'https://www.instagram.com/italo_castro_azati',
    url2: 'https://www.instagram.com/italo_castro_azati',
  },
];

function Team() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const responsiveOptions = [
    {
      breakpoint: '1300px',
      numVisible: 4,
      numScroll: 2,
    },
    {
      breakpoint: '1024px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '1023px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const renderCard = (card) => (
    <div className="adv-card" key={card.image}>
      <div className="div-image-adv-card">
        <img className="image-adv-card" src={card.image} alt="imagem da(o) advogado" />
      </div>
      <div className="adv-card-content">
        <h1 className="h1-adv-card">
          {card.title.split('\n').map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ))}
        </h1>
        <div className="card-social-btns">
          <IconButton className="social-icon" onClick={() => window.open(card.url1)}>
            <FaLinkedin />
          </IconButton>
          <IconButton className="social-icon" onClick={() => window.open(card.url2)}>
            <FaInstagram />
          </IconButton>
        </div>
      </div>
    </div>
  );

  const renderDesktopView = () => (
    <div className="adv-cards">
      {teamData.map(renderCard)}
    </div>
  );

  const renderMobileView = () => (
    <div className="cards-mobile">
      <Carousel
        value={teamData}
        itemTemplate={renderCard}
        numVisible={4}
        numScroll={4}
        responsiveOptions={responsiveOptions}
        className="custom-carousel-team"
        contentClassName="custom-carousel-content"
        circular
      />
    </div>
  );

  return (
    <div className="team" id="team">
      <h1 className="h1-team">Nossa equipe</h1>
      {isMobile ? renderMobileView() : renderDesktopView()}
    </div>
  );
}

export default Team;
