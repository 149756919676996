/* eslint-disable max-len */
import React from 'react';
import '../styles/about.css';
import prancheta from '../images/prancheta1.png';

function About() {
  return (
    <div className="about" id="about">

      <a
        className="link-whatsapp"
        href="https://api.whatsapp.com/send?phone=5584996398379&text=Olá,%20preciso%20de%20assistência%20jurídica%20e%20gostaria%20de%20mais%20informações."
        target="_blank"
        rel="noreferrer"
      >
        <button type="button" className="about-btn-mobile">
          FALAR COM UM ADVOGADO
        </button>
      </a>
      <div className="about-bg">

        <div className="about-text">
          <h1 className="h1-about">
            Sobre nós
          </h1>
          <p className="p-about">
            Uma advocacia moderna, com responsabilidade e compromisso.
            <br />
            <br />
            O escritório
            {' '}
            <span>
              Brazão, Castro e Miranda Advogados
            </span>
            {' '}
            atua com compromisso e dedicação em todo país. Nossa equipe possui vasta experiência em diversas áreas do direito, com reconhecimento nacional e internacional. Nosso compromisso com atuação orientada para resultados e humanizada se reflete na eliminação de burocracias, proporcionando um atendimento que valoriza a ética, a transparência e a obtenção de resultados positivos para nossos clientes.
          </p>
        </div>
        <div className="div-image-about">
          <img src={prancheta} alt="prancheta" className="image-about" />
        </div>
      </div>
    </div>
  );
}

export default About;
