import React from 'react';
import imageAbout from '../images/bracami7.png';
import '../styles/home.css';

function Home() {
  function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  if (isIOS()) {
    document.body.classList.add('ios');
  }
  return (
    <div className="home" id="home">
      <div className="home-text">
        <h1 className="h1-home">
          Advocacia especializada com atuação em todo país
        </h1>
        <p className="p-home">
          Com profissionais com vasta experiência nas
          <br />
          {' '}
          mais diversas áreas do direito, nossa atuação
          <br />
          {' '}
          é pautada no compromisso e na
          <br />
          {' '}
          responsabilidade com nossos clientes.
        </p>
      </div>
      <div className="home-image-div">
        <img src={imageAbout} alt="advogados" className="home-image" />
      </div>
    </div>
  );
}

export default Home;
