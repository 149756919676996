import React from 'react';
import { Carousel } from 'primereact/carousel';
import '../styles/services.css';
import services1 from '../images/services1.jpg';
import services2 from '../images/services2.jpg';
import services3 from '../images/services3.jpg';
import services4 from '../images/services4.jpg';
import services5 from '../images/services5.jpg';
import services6 from '../images/services6.jpg';

const serviceData = [
  {
    image: services1,
    title: 'DIREITO PREVIDENCIÁRIO',
    text: 'BPC, aposentadoria por invalidez, aposentadoria por idade, etc.',
  },
  {
    image: services2,
    title: 'DIREITO DO CONSUMIDOR',
    text: 'Empréstimos bancários fraudulentos, cobrança indevida, etc.',
  },
  {
    image: services3,
    title: 'DIREITO TRABALHISTA',
    text: 'Verbas rescisórias, reconhecimento de vínculo, acidente de trabalho, etc.',
  },
  {
    image: services4,
    title: 'DIREITO IMOBILIÁRIO',
    text: 'Regularização de imóveis, contratos, usucapião, direitos reais, etc.',
  },
  {
    image: services5,
    title: 'DIREITO CONDOMINIAL',
    text: 'Cobranças de taxas condominiais, contratos de terceirização, etc.',
  },
  {
    image: services6,
    title: 'DIREITO\nDA SAÚDE',
    text: 'Demandas contra o SUS e contra planos de saúde, etc.',
  },
];

function Services() {
  const responsiveOptions = [
    {
      breakpoint: '1301px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const itemTemplate = (card) => (
    <div className="card-carousel">
      <div className="div-img-card">
        <img src={card.image} alt={card.title} className="img-card-services" />
      </div>
      <div className="div-text-card">
        <h1 className="h1-services-card">
          {card.title.split('\n').map((line) => (
            <React.Fragment key={line}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </h1>
        <p className="p-services-card">{card.text}</p>
      </div>
    </div>
  );

  return (
    <div className="services" id="services">
      <div className="services-itens">
        <h1 className="h1-services">
          Nossos Serviços
        </h1>
        <Carousel
          value={serviceData}
          itemTemplate={itemTemplate}
          numVisible={3}
          numScroll={3}
          responsiveOptions={responsiveOptions}
          className="custom-carousel"
          contentClassName="custom-carousel-content"
          indicatorPosition="bottom"
          circular
        />
      </div>
    </div>
  );
}

export default Services;
