/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import './App.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import wppLogo from './images/logo512.png';
import socialShare from './images/social-share.jpg';
import Team from './components/Team';
import ContactUs from './components/ContactUs';
import Footer from './components/footer';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Brazão, Castro e Miranda Advogados - Advocacia Especializada</title>
        <meta name="description" content="Advocacia especializada com atuação em todo o país. Compromisso e responsabilidade com nossos clientes." />
        <meta name="keywords" content="advocacia, direito, direito previdenciário, direito do consumidor, direito trabalhista, direito imobiliário, direito condominial, direito da saúde, advogado, advogados" />
        <meta name="author" content="Brazão, Castro e Miranda Advogados" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://landingpage-advogados.vercel.app" />
        <meta property="og:title" content="Brazão, Castro e Miranda Advogados - Advocacia Especializada" />
        <meta property="og:description" content="Advocacia especializada com atuação em todo o país. Compromisso e responsabilidade com nossos clientes." />
        <meta property="og:image" content={socialShare} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://landingpage-advogados.vercel.app" />
        <meta property="twitter:title" content="Brazão, Castro e Miranda Advogados - Advocacia Especializada" />
        <meta property="twitter:description" content="Advocacia especializada com atuação em todo o país. Compromisso e responsabilidade com nossos clientes." />
        <meta property="twitter:image" content={socialShare} />
      </Helmet>
      <Header />
      <div className="content">
        <Home />
        <About />
        <Services />
        <Team />
        <ContactUs />
        <Footer />
        <FloatingWhatsApp
          phoneNumber="5584996398379"
          accountName="Brazão, Castro e Miranda Advogados"
          avatar={wppLogo}
          statusMessage="Brazão, Castro e Miranda Advogados"
          chatMessage="Olá, como podemos ajudar?"
          messageDelay={2}
          darkMode="true"
          allowClickAway="true"
        />
      </div>
    </div>
  );
}

export default App;
