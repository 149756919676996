/* eslint-disable max-len */
import React from 'react';
import desk2 from '../images/desk2.png';
import '../styles/contactUs.css';

function ContactUs() {
  function isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  if (isIOS()) {
    document.body.classList.add('ios');
  }
  return (
    <div className="contact" id="contact">
      <div className="contact-content">
        <div className="contact-img-div">
          <img src={desk2} alt="desk" className="img-contact" />
        </div>
        <div className="contact-text">
          <h1 className="h1-contact">
            Fale conosco
          </h1>
          <p className="p-contact">
            Fale conosco e descubra como podemos contribuir para solucionar o seu problema ou para o crescimento do seu negócio. Nossos profissionais são treinados para oferecer a melhor solução pelo menor custo. Clique para saber mais!
          </p>
          <a
            className="link-whatsapp"
            href="https://api.whatsapp.com/send?phone=5584996398379&text=Olá,%20preciso%20de%20assistência%20jurídica%20e%20gostaria%20de%20mais%20informações."
            target="_blank"
            rel="noreferrer"
          >
            <button type="button" className="contact-btn">
              FALAR COM UM ADVOGADO
            </button>
          </a>
        </div>
      </div>
    </div>

  );
}

export default ContactUs;
