/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import {
  FaEnvelope,
  FaLocationDot,
  FaWhatsapp,
} from 'react-icons/fa6';
import { IconButton } from '@mui/material';
import '../styles/footer.css';
import { Link } from 'react-scroll';

function Footer() {
  const menuItems = [
    { text: 'início', id: 'home' },
    { text: 'sobre nós', id: 'about' },
    { text: 'nossos serviços', id: 'services' },
    { text: 'nossa equipe', id: 'team' },
    { text: 'fale conosco', id: 'contact' },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="footer">
        <div className="footer-content">
          <div className="menu-footer">
            <h1 className="h1-menu-footer">
              Menu
            </h1>
            <hr />
            <ul>
              {Object.values(menuItems).map((item) => (
                <li key={item.id}>
                  <Link
                    to={item.id}
                    spy
                    smooth
                    offset={isMobile ? -80 : -20}
                    duration={500}
                    className="link-footer"
                    key={item.id}
                  >
                    {item.text.toUpperCase()}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="contatos-footer">
            <h1 className="h1-contatos-footer">
              Contatos
            </h1>
            <hr />
            <ul>
              <li>
                <IconButton
                  className="contatos-icon"
                >
                  <FaWhatsapp className="contato-icone" />
                </IconButton>
                + 55 (84) 99639-8379
              </li>
              <li>
                <IconButton
                  className="contatos-icon"
                >
                  <FaEnvelope className="contato-icone" />
                </IconButton>
                contato@brazaocastromiranda.adv.br
              </li>
              <li>
                <IconButton
                  className="contatos-icon"
                >
                  <FaLocationDot className="contato-icone" />
                </IconButton>
                <span className="endereco">
                  RUA PEROBA, N.º NOVA PARNAMIRIM, N.º 134, PRIMEIRO ANDAR,
                  <br />
                  {' '}
                  PARNAMIRIM/RN, CEP: 59152-050
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bar">
        <p>
          copyright © 2024 todos os direitos reservados
          <br />
          {' '}
          Brazão, Castro & Miranda Advogados
        </p>
      </div>
    </>
  );
}

export default Footer;
