/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import {
  AppBar, Toolbar, Typography, Drawer, IconButton, List, ListItem, ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../styles/header.css';
import { Link } from 'react-scroll';
import logo from '../images/Logo2.png';

const StyledAppBar = styled(AppBar)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  height: '120px',
  backgroundColor: 'transparent !important',
  backgroundImage: 'none',
  transition: 'background-color 0.3s ease-in-out, height 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important',
  '&.solid': {
    backgroundColor: '#1d1b1b !important',
    height: '100px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2) !important',
    top: '0',
    marginTop: '0',
  },
  '@media (max-width: 480px)': {
    transition: 'background-color 0.3s ease-in-out, height 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important',
    '&.solid': {
      backgroundColor: '#1d1b1b !important',
      height: '80px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2) !important',
    },
  },
});

const ToolbarComponents = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const menuItems = [
    { text: 'início', id: 'home' },
    { text: 'sobre nós', id: 'about' },
    { text: 'nossos serviços', id: 'services' },
    { text: 'nossa equipe', id: 'team' },
  ];

  const renderDesktopMenu = () => (
    <div className="desktop-menu">
      {menuItems.map((item) => (
        <Link
          to={item.id}
          spy
          smooth
          offset={-20}
          duration={500}
          className="menu-item"
          key={item.id}
        >
          {item.text.toUpperCase()}
        </Link>
      ))}
    </div>
  );

  const renderMobileMenu = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      className="drawer"
    >
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.id}
            onClick={() => setDrawerOpen(false)}
          >
            <Link
              to={item.id}
              spy
              smooth
              offset={-60}
              duration={500}
              className="menu-item"
            >
              <ListItemText primary={item.text.toUpperCase()} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  useEffect(() => {
    const handleScroll = () => {
      const headerElement = document.querySelector('.header');
      if (window.scrollY > 100) {
        headerElement.classList.add('solid');
      } else {
        headerElement.classList.remove('solid');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledAppBar className="header" elevation={0}>
      <Toolbar className="toolbar">
        <ToolbarComponents>
          <Typography variant="h1" component="div-image-logo" className="logo">
            <img src={logo} alt="logo" className="logo-img" />
          </Typography>
          {!isMobile && renderDesktopMenu()}
          {isMobile && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon className="iconDrawer" />
            </IconButton>
          )}
        </ToolbarComponents>
      </Toolbar>
      <a
        className="link-whatsapp"
        href="https://api.whatsapp.com/send?phone=5584996398379&text=Olá,%20preciso%20de%20assistência%20jurídica%20e%20gostaria%20de%20mais%20informações."
        target="_blank"
        rel="noreferrer"
      >
        <button type="button" className="header-btn">
          FALAR COM UM ADVOGADO
        </button>
      </a>
      {isMobile && renderMobileMenu()}
    </StyledAppBar>
  );
}

export default Header;
